import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { PageContextPropsType } from '../../../i18n/i18n'
import LayoutSimpleFooter from '../../components/LayoutSimpleFooter'
import SEO from '../../components/SEO'
import FormEbook from '../../components/FormEbook'
import classNames from 'classnames/bind'
import * as EbookPageStyles from '../../styles/EbookPage.css'

const cx = classNames.bind(EbookPageStyles)

export default function EbookPage({ pageContext }: PageContextPropsType) {
  const reCaptchaKey = process.env.GATSBY_RECAPTCHA_CLIENT_SECRET_KEY || ''

  return (
    <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
      <LayoutSimpleFooter pageContext={pageContext}>
        <SEO
          title="KARTE Blocks 資料ダウンロード | 製品概要や活用事例を紹介"
          description="KARTE Blocksのプロダクト概要やユースケースを紹介した資料をダウンロードいただけます。"
          path="/ebook/"
        />

        <main className={cx('Outer')}>
          <div className={cx('Container')}>
            <section className={cx('Content')}>
              <h1 className={cx('Headline')}>KARTE Blocks 資料</h1>

              <StaticImage src="../../images/ebook/thumbnail.jpg" alt="資料の表紙" />

              <section className={cx('Details')}>
                <h2 className={cx('Details_Headline')}>資料の内容</h2>

                <p className={cx('Details_Summary')}>
                  KARTE
                  Blocksは、サイトにタグを1行設置するだけで、サイト上のあらゆる構成要素を「ブロック」に分解し、ノーコードで更新、評価、改善することができる画期的なサイト管理システムです。
                  <br />
                  無料版をすぐにお試し可能です。
                </p>

                <ol className={cx('Details_Outline')}>
                  <li className={cx('Details_Item')}>KARTE Blocksとは</li>
                  <li className={cx('Details_Item')}>BMS(Block Management System)とは</li>
                  <li className={cx('Details_Item')}>KARTE Blocks Case紹介</li>
                  <li className={cx('Details_Item')}>導入企業様一例</li>
                  <li className={cx('Details_Item')}>導入・活用サポートについて</li>
                  <li className={cx('Details_Item')}>プランについて</li>
                </ol>
              </section>
            </section>

            <FormEbook actionUrl="https://deal.karte.io/l/819613/2021-04-08/2x1nz" actionName="blockskarteio/ebook/" />
          </div>
        </main>
      </LayoutSimpleFooter>
    </GoogleReCaptchaProvider>
  )
}
